<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ webname }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ webname }}
          </b-card-title>
          <b-card-text class="mb-2">
            กรุณายืนยันตัวตนเพื่อเข้าทำงานค่ะ
          </b-card-text>

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="ชื่อผู้ใช้"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ชื่อผู้ใช้"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="XXX-XXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                  <!-- <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="รหัสผ่าน"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                variant="primary"
                block
                @click="retrieveOTP"
              >
                ขอรหัสเข้างาน
              </b-button>
              <b-form-group>
                <div
                  class="d-flex justify-content-between"
                  style="margin-top: 10px;"
                >
                  <label for="login-password">รหัสเข้างาน</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="รหัสเข้างาน"
                  vid="otp-code"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="otp-code"
                      v-model="otpcode"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      type="text"
                      name="login-otpcode"
                      placeholder="รหัสเข้างาน"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                เข้าสู่ระบบ
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      webname: ((location.host.split('.'))[0] === 'richs-v2' ? ((location.host.split('.'))[1]).toUpperCase() : ((location.host.split('.'))[0]).toLowerCase()) || 'RICHS',
      // webname: ('aaa.bbb.ccc'.split('.'))[1],
      status: '',
      password: '',
      userEmail: '',
      otpcode: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async retrieveOTP() {
      try {
        const { data: getOTPResult } = await this.$http.post('get_otp', { phoneno: this.userEmail, password: this.password })
        if (getOTPResult.success) {
          this.$swal({
            icon: 'success',
            title: 'ขอรหัสเข้างานเรียบร้อย',
            text: getOTPResult.message || 'ขอรหัสเข้างานเรียบร้อย กรุณาเช็ค OTP ของคุณ',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.$swal({
            icon: 'danger',
            title: 'ขอรหัสเข้างานไม่สำเร็จ',
            text: getOTPResult.error_message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (err) {
        this.$swal({
          icon: 'danger',
          title: 'ขอรหัสเข้างานไม่สำเร็จ',
          text: err.response.data.error,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        // eslint-disable-next-line no-empty
      }
    },
    async login() {
      try {
        const { data: loginResult } = await this.$http.post('login', { phoneno: this.userEmail, password: this.password, otp_code: this.otpcode })
        if (loginResult.success) {
          loginResult.user.accessToken = loginResult.token
          loginResult.user.refreshToken = loginResult.token
          loginResult.user.ability = [{ action: 'manage', subject: 'all' }]
          loginResult.user.role = 'admin'
          localStorage.setItem('userData', JSON.stringify(loginResult.user))
          useJwt.setToken(loginResult.token)
          useJwt.setRefreshToken(loginResult.token)
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
          this.$ability.update(loginResult.user.ability)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `สวัสดี ${loginResult.user.name}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'เข้าสู่ระบบสำเร็จ ขอให้วันนี้โชคดีปังๆ!',
            },
          })

          if (loginResult.mode === 'GAUTH' && loginResult.user.bound_secret === 0) {
            this.$router.replace({ name: 'pages-account-setting' })
          } else {
            location.href = '/'
          }
        } else {
          this.$swal({
            icon: 'danger',
            title: 'ขอรหัสเข้างานไม่สำเร็จ',
            text: loginResult.error_message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'เข้าสู่ระบบไม่สำเร็จ',
          text: err,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        // eslint-disable-next-line no-empty
      }
      // this.$refs.loginForm.validate().then(success => {
      //   if (success) {
      //     useJwt.login({
      //       email: this.userEmail,
      //       password: this.password,
      //     })
      //       .then(response => {
      //         const { userData } = response.data
      //         useJwt.setToken(response.data.accessToken)
      //         useJwt.setRefreshToken(response.data.refreshToken)
      //         localStorage.setItem('userData', JSON.stringify(userData))
      //         this.$ability.update(userData.ability)

      //         // ? This is just for demo purpose as well.
      //         // ? Because we are showing eCommerce app's cart items count in navbar
      //         this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

      //         // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      //         this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
      //           .then(() => {
      //             this.$toast({
      //               component: ToastificationContent,
      //               position: 'top-right',
      //               props: {
      //                 title: `Welcome ${userData.fullName || userData.username}`,
      //                 icon: 'CoffeeIcon',
      //                 variant: 'success',
      //                 text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
      //               },
      //             })
      //           })
      //       })
      //       .catch(error => {
      //         this.$refs.loginForm.setErrors(error.response.data.error)
      //       })
      //   }
      // })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
